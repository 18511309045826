.loan-disbursement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding: 40px;
  gap: 24px;

  .process-img-container {
    display: flex;
    justify-content: center;
    img {
      width: 8rem;
      height: 8rem;
    }
  }

  .process-main-content {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    align-items: center;

    :first-child {
      font-size: 20px;
      // font-weight: 700;
      color: var(--active-blue-color);
      text-align: center;
      margin-bottom: 1rem;
    }

    .upload-additional-document {
      font-size: 16px;
      // font-weight: 600;
      color: var(--active-blue-color);
      text-align: center;
    }

    .process-subtitle-1 {
      color: var(--primary1-color);
      text-align: center;
      font-size: 16px;
      // font-weight: 600;
      line-height: 24px;
    }
    .process-subtitle-2 {
      color: var(--primary1-color);
      text-align: center;
      font-size: 14px;
      // font-weight: 600;
      line-height: 24px;
    }
  }
}
