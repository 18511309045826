.card-container {
  display: flex;
  flex-direction: column;

  .card-header {
    display: inline-flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    justify-content: center;
    align-items: center;
    background: var(--Neutrals-Neutral-10, #e5e5e8);

    p {
      color: var(--Neutrals-Neutral-100, #161719);
      text-align: center;

      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
  }
  .card-content-container {
    // width: 358px;
    height: 30rem;
    flex-shrink: 0;
    // padding: 85px 24px;
    border-radius: 8px;
    background: var(--Base-White, #fff);

    box-shadow: 0px 2px 4px -2px rgba(22, 23, 25, 0.06),
      0px 4px 8px -2px rgba(22, 23, 25, 0.1);

    p {
      color: var(--primary-color);

      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }
}
