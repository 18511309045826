.button {
  padding: 12px 16px;
  border: 1px solid var(--primary-lightgrey-color);
  border-radius: 8px;
  // margin-bottom: 19px;
}
.button-border {
  border: 1px solid #008042;
}
.button-error {
  border: 1px solid red;
}
.row {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  .inner-row {
    display: flex;
    gap: 8px;
    word-break: break-all;
  }
  .label-name {
    font-size: 12px;
    color: var(--primary-grey-color);
  }
  correct-icon {
    margin-right: 8px;
  }
}
.upload {
  color: var(--active-blue-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  .div {
    display: flex;
    background-color: red;
  }
}
