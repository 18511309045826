.esign-pending-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  gap: 24px;

  .process-img-container {
    display: flex;
    justify-content: center;
    img {
      width: 64px;
      height: 64px;
    }
  }

  .process-main-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    .upload-additional-document {
      font-size: 16px;
      font-weight: 600;
      color: #134cde;
    }

    .process-subtitle {
      color: var(--primary1-color);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.esign-model-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  padding: 20px 0 20px 0;
  outline: none;
  border-radius: 8px;
  height: 97%;
}
