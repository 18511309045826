.container {
  .upload-document-body {
    // position: relative;
    margin-inline: 16px;
    margin-top: 24px;
    @media screen and (max-width: 376px) {
      margin-inline: 0;
    }
    .bank-statement-box {
      margin: 2.5rem 0;

      .last-bank-statement {
        margin-top: 6px;
        display: flex;
        gap: 8px;
        align-items: center;
        p {
          font-size: 12px;
          color: var(--primary-grey-color);
        }
        input {
          margin: 0;
        }
      }
      .non-error-helper-msg {
        color: var(--active-blue-color);
        font-size: 12px;
        padding: 0 16px;
        margin-top: 4px;
      }
      .error-msg {
        color: #cc0000;
        font-size: 12px;
        padding: 0 16px;
        margin-top: 4px;
      }
      .error-msg-6-months {
        b {
          color: #cc0000;
        }
        font-size: 12px;
        padding: 0 16px;
        margin-top: 4px;
      }
      .upload-additional-doc {
        color: var(--active-blue-color);
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .pdf-upload-instruction {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      .instruction-content {
        color: #777;
        font-size: 12px;
        line-height: 150%;
        font-weight: 400;
        :last-child {
          color: var(--active-blue-color);
          margin: 10px 0 0 0;
          span {
            font-weight: 700;
            text-decoration-line: underline;
          }
        }
      }
    }
    .file-pass-instruction {
      margin-top: 2.5rem;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        color: #161719;
        margin-bottom: 8px;
      }
      margin-bottom: 20px;
    }
  }
  .submit-and-next-btn-box {
    width: 100%;
    position: sticky;
    top: 100%;
  }
}
