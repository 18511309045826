.gray-card-container {
  background-color: #f9f9f9;
  border-radius: 1.5rem;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add shadow here
  transition: box-shadow 0.3s ease; // Optional: Adds a smooth transition for the shadow

  .label {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
  }
}
