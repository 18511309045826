.container {
  .udyam-certificate-body {
    position: relative;
    margin-top: 40px;
    .udyam-input-box {
      margin-top: 27px;
      .instruction-box {
        margin-top: 15px;
        display: flex;
        gap: 8px;
        color: #767888;
        .instruction-content {
          font-size: 12px;
        }
      }
      .error-msg {
        font-size: 12px;
        color: #fe370c;
      }
    }
    .udyam-details-box {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  .udyam-submit-btn {
    position: sticky;
    width: 100%;
    top: 100%;
    margin-top: 24px;
  }
}
