.modal {
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    .modal-labels {
      display: flex;
      flex-direction: column;
      // gap: 4px;

      .modal-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: var(--primary-color);
        text-align: center;
      }

      .modal-subtitle {
        font-size: 14px;
        font-weight: 500;
        color: var(--primary-grey-color);
        text-align: left;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
    }

    .modal-checkbox-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .item-row {
        display: flex;
        gap: 8px;
        align-items: center;

        label {
          font-size: 18px;
          font-weight: 600;
          color: var(--primary-color);
        }
      }
      .info-container {
        margin-top: 0.5rem;
        padding: 0.5rem;
        background-color: var(--secondary-lightgrey-color);
      }
    }
  }
}
