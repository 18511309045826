.selfie-body {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 0px 15px;
  justify-content: space-between;

  .selfie-inner-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .selfie-container {
      margin-top: 50px;
      margin-inline: auto;

      .selfie-img {
        width: 137px;
        height: 182px;
        border-radius: 8px;
        align-self: center;
        object-fit: cover;
      }

      .retake-btn {
        outline: 0;
        border: 0;
        padding: 0;
        background-color: unset;
        color: var(--active-blue-color);
        font-size: 20px;
        font-family: inherit;
        font-weight: 600;
        display: block;
        margin: auto;
        padding: 6px 24px;
      }
    }

    .selfie-note-container {
      background-color: var(--ghostwhite-color);
      display: flex;
      gap: 8px;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;

      img {
        align-self: flex-start;
        width: 15px;
        height: 15px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-grey-color);
        line-height: 18px;
      }
    }
  }
}

#camera {
  visibility: hidden;
  background-color: black;
}

#camera,
#camera-view,
#camera-sensor,
#camera-output {
  position: absolute;
  width: 100%;
  height: 100vh;
  max-width: 425px;
  object-fit: cover;
  top: 0;
  left: 0;
}

#camera-view,
#camera-sensor {
  transform: scaleX(-1);
  filter: FlipH;
}

#camera-trigger {
  width: 72px;
  height: 72px;
  border: 0;
  outline: 0;
  padding: 0;
  border-radius: 50%;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 30px;
}

.taken {
  width: 137px !important;
  transform: scaleX(-1);
}
