
.progress-container {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding-inline: 10px;

    .progress-bar {
        height: 4px;
        border-radius: 8px;
        width: 40px;
        background: #E5E5E8;
    }

    .active-bar {
        background: #134CDE;
    }
}

.hide-progress {
    visibility: hidden;
}