
  

    .input-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        // height: 300px;
      
      }

