.container{
position: relative;
    .additional-doc-input-box{
        margin-top: 19px;
        position: relative;
        .singleInput-box{
            margin-bottom: 19px;
        }
        .doc-instruction{
            display: flex;
            gap: 8px;
            font-size: 12px;
            color: #767888;
            align-items: center;
            margin-bottom: 20px;
        }
    }
    .submit-btn{
        position: sticky;
        top:100%;
        width: 100%;
    }   
   
}
.additional-doc-error{
    font-size: 12px;
    margin-top: 4px;
    margin-inline: 16px;
    color: #c00;
  }