.header-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
  height: 8vh;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding-inline: 5px;
  height: 8vh;
  width: 100%;
}

.progress-icon {
  background-color: red;
}

.help-icon {
  width: 24px;
  height: 24px;
}
