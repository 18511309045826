// Dropdown.scss
.dropdown-field {
  position: relative;
  height: 4.5vh;
  max-height: 10vh;
  display: flex;
  flex-flow: column-reverse;
  line-height: normal;
  border: 1px solid var(--primary-lightgrey-color);
  border-radius: 8px;
  padding: 4px 16px 4px 16px;
  justify-content: center;
  z-index: 1;

  .dropdown-select {
    font-size: 16px;
    font-weight: 400;
    border: 0;
    color: var(--primary-color);
    border-radius: 0;
    cursor: pointer;
    background: transparent;
  }

  .dropdown-label {
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color);
  }
  .dropdown-label:isopen {
    font-size: 16px;
    font-weight: 400;
    color: red;
  }
}

.dropdown-label,
.dropdown-select {
  transition: all 0.3s;
  touch-action: manipulation;
}

.dropdown-select:focus {
  outline: 0;
}

.dropdown-select:focus + .dropdown-label {
  cursor: text;
  transform: translate(0, 0.6rem);
}

.dropdown-select + .dropdown-label {
  transform: translate(0, 1.7rem);
}

.dropdown-select:focus + .dropdown-label,
.dropdown-select:not(:placeholder-shown) + .dropdown-label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 3px;
  color: var(--primary-grey-color);
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}
.dropdown-option:isopen {
  background-color: red;
}
