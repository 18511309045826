.offer-pending-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 62px;
  gap: 24px;

  .offer-img-container {
    display: flex;
    justify-content: center;

    img {
      width: 10rem;
      height: 10rem;
    }
  }

  .offer-main-content {
    display: flex;
    flex-direction: column;

    .offer-subtitle {
      color: var(--primary1-color);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
