.active-form-btn {
  background-color: var(--primary-color);
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  // margin-bottom: 50px;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: 600;
    color: var(--secondary-color);
    margin: 0;
  }
}

.disable-form-btn {
  background-color: var(--secondary-lightgrey-color);
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  cursor: not-allowed;

  span {
    font-size: 16px;
    font-weight: 600;
    color: var(--secondary-grey-color);
    margin: 0;
  }
}
