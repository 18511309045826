.enach-detail-body {
  padding: 10px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  // height: 78vh;

  .input-consent-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
