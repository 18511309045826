

.content-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    p {
        margin: 0;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        color: var(--primary-color);
        @media screen and (max-width: 320px) {
            font-size: 22px;
        }
    }

    .subTitle{
        font-size: 14px;
        font-weight: 400;
        color: var(--primary-color);
        line-height: 21px;
      }
}
