.offer-container {
  width: 100%;
  position: relative;

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 60px;
      width: 60px;
    }

    .congratulations {
      color: var(--success-color);
      font-size: 24px;
      font-weight: 600;
      line-height: 150%;
    }
    .sub-head {
      color: var(--primary1-color);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .card-amount-container {
    display: flex;
    padding: 15px 0px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-radius: 12px 12px 0px 0px;
    background: var(--primary-color);

    .amount {
      color: var(--secondary-color);
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
    }

    .reset {
      color: var(--secondary-color);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    p {
      color: var(--light-text);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .card-loan-details-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid var(--light-text);
    border-bottom: 1px solid var(--light-text);
    border-left: 1px solid var(--light-text);
    background: var(--secondary-color);

    div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      :first-child {
        color: var(--primary-grey-color);

        /* Body/Small/Medium */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
      :last-child {
        color: var(--primary-grey-color);

        /* Body/Small/Medium */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }

  .slider-label {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .slider-value {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .tenure-slider-container {
    margin: 20px 15px 6rem 15px;
    :last-child {
      color: var(--active-blue-color);
      font-family: Montserrat;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3125rem;
      margin: 0.5rem 0;
    }
  }
  .amount-slider-container {
    margin: 20px 15px;
  }
  .action-button-container {
    position: sticky;
    top: 100%;
    margin: 9rem 0 0 0;
  }
  .button-container {
    position: sticky;
    top: 100%;
  }
  .expiry-container {
    display: flex;
    // width: 342px;
    padding: 4px 6px;
    margin: 1.5rem 0 0 0;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Warning-Warning-10, #eda12f);
    background: var(--Warning-Warning-0, #fff5e6);

    p {
      color: var(--Neutrals-Neutral-100, #161719);

      /* Body/Micro/Medium */
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
  }
}
