.profile-container {
    width: 100%;
    .pic-button-container {
        margin-block: 40px;
        .pic-container {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: gray;
            margin-inline: auto;
            overflow: hidden;
            .pic {
                width: 100%;
            }
        }
        .change-btn {
            outline: 0;
            border: 0;
            padding: 0;
            background-color: unset;
            font-family: inherit;
            font-weight: 600;
            display: block;
            margin: auto;
            padding: 6px 24px;
            margin-top: 8px;
            color: rgba(19, 76, 222, 1);
            cursor: pointer;
        }
    }
    
    table {
        width: 100%;
        td{
            padding: 8px;
            font-weight: 600;
        }
        td:first-child{
            color: rgba(118, 120, 136, 1);
            width: 30%;
        }
        td:last-child{
            color: var(--primary-color);
        }
    }
}