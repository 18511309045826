.faq-container {
  padding: 0rem 1rem;
  max-height: 80vh;
  flex-direction: column;
  display: flex;
  overflow: auto;

  p {
    color: var(--primary1-grey-color);
  }

  .component-container {
    margin: 1rem 0rem;
  }
  .card-content-container {
    // padding: 0.5rem;
    .note {
      font-weight: 600;
      padding: 1rem;
    }
  }
  .descn {
    color: var(--Primary-Blue-Primary-Blue-70, #134cde);

    /* Body/Large/Semibold */
    // display: flex;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }
}
