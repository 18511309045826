.contact-container {
  .contact-card {
    margin-top: 24px;
    border: 1px solid #d8d9de;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 2px 4px -2px rgba(22, 23, 25, 0.06),
      0px 4px 8px -2px rgba(22, 23, 25, 0.1);
    font-weight: 600;

    .service-company {
      padding: 8px 16px;
      height: 24px;
      background-color: #e5e5e8;

      .company-logo {
        height: 100%;
      }
    }

    .contact-info {
      margin: 24px 10px;

      .contact-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        align-items: center;
        @media only screen and (max-width: 375px) {
          flex-direction: column;
        }

        .contact-label {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: flex-start;
        }

        .contact-details {
          text-align: end;
          @media only screen and (max-width: 375px) {
            text-align: left;
            margin-top: 4px;
          }
          .contact-name {
            font-size: 14px;
            color: #767888;
          }
          .contact {
            display: flex;
            overflow-wrap: anywhere;
          }
        }
      }
    }
  }
}
