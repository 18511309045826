.detail-body {
  padding: 10px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // height: 78vh;

  .input-consent-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 24px;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .footer-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
.tnc-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  div {
    p {
      color: red;
    }
  }
}
