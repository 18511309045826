.input-container {
    display: flex;
    flex-direction: column;
    // height: 300px;
    margin-top: 10px;
  }

.consent-container {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 10px 0px;

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--primary-grey-color);

      span {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }
  }