p {
  margin: 0;
}

.lending-container {
  height: 100vh;

  .arrow-container {
    display: flex;
    background: #505050;
    height: 2rem;
  }

  .lending-body {
    overflow-y: scroll;
    height: 92%;

    .header-container {
      display: flex;
      background: linear-gradient(#505050, #101010);
      gap: 6px;
      flex-direction: column;
      padding-inline: 5px;

      .title-container {
        display: flex;
        padding-block: 6px;
        padding-inline: 10px;
        justify-content: space-between;

        .content-container {
          display: flex;
          flex: 0.8;

          .content-title {
            color: var(--Base-White, #fff);

            /* Heading/H3/Semibold */
            font-size: 24px;
            font-weight: 600;
            line-height: 150%; /* 36px */
          }
        }

        .header-img {
          display: flex;
          flex: 0.2;

          .lending-img {
            height: 100px;
          }
        }
      }
    }

    .steps-journey-container {
      padding-block: 10px;
      padding-inline: 10px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 85%;
      justify-content: space-between;

      .footer-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .consent-container {
          display: flex;
          align-items: center;
          margin: 0;

          span {
            color: var(--primary-grey-color);
            font-size: 0.75rem;
            font-weight: 400;

            span {
              color: var(--primary-color);
              font-size: 0.75rem;
              font-weight: 400;
              text-decoration-line: underline;
            }
          }
        }
      }

      .step-row {
        display: flex;
        gap: 15px;

        .step-profile {
          display: flex;
          align-items: center;
          flex-direction: column;

          .step-profile-bg {
            display: flex;
            width: 25px;
            height: 25px;
            background: linear-gradient(#c1c1c1, #848484);
            border-radius: 25px;
            padding: 8px;
          }

          .vl {
            height: 25px;
            border-width: 0.7px;
            border-style: dashed;
            border-color: var(--secondary-grey3-color);
          }
        }

        .step-content {
          display: flex;
          flex-direction: column;
          gap: 4px;

          p {
            margin: 0;
            text-align: left;
            color: var(--primary-color);
          }

          :first-child {
            font-weight: 600;
            font-size: 16px;
          }

          :nth-child(2) {
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.enquiry-modal-container {
  display: flex;
  align-items: center;
  padding: 10px;

  .enquiry-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: auto;
    padding: 10px;
    border-radius: 8px;
    gap: 8px;
    width: 100%;

    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-color);
    }
  }
}
