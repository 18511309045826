body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 425px;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-gradiant: linear-gradient(90deg, #505050, #101010);
  --secondary-gradiant: linear-gradient(45deg, #c1c1c1, #848484);
  --primary-color: #161719; 
  --primary1-color: #151515;
  --secondary-color: #ffffff;
  --primary-grey-color: #767888;
  --secondary-grey-color: #c0c1c8;
  --secondary-grey2-color: #9e9e9e;
  --secondary-grey3-color: #bfc1c7;
  --primary-lightgrey-color: #cccdd3;
  --secondary-lightgrey-color: #e5e5e8;
  --ghostwhite-color: #f1f1f3;
  --active-blue-color: #134cde;
  --success-color: #008042;
  --caution-color: #cc0000;
  --error-color: #d71a00;
  --light-text: #d8d9de;
}
