.view-files-container {
  padding: 10px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4rem;

  .kfs-contnet {
    display: flex;
    flex-direction: column;

    div {
      display: flex;

      &:nth-child(even) {
        background-color: #f5f6f8;
      }

      p {
        display: flex;
        flex: 1;
        padding: 1rem;
        color: var(--primary-color);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
      }
      :last-child {
        display: flex;
        flex: 1.5;
      }
    }
  }

  .consent-container {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 1.5rem 0px;

    span {
      color: var(--primary-grey-color);
      font-family: Montserrat;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;

      span {
        cursor: pointer;
        color: var(--Neutrals-Neutral-100, #161719);
        font-family: Montserrat;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration-line: underline;
        user-select: none;
      }
    }
  }
  .helper-container {
    margin: 1rem 0;

    p {
      color: var(--active-blue-color);
      line-height: 1.5rem;
      font-size: 12px;
    }
  }
}

.esign-model-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  padding: 20px 0 20px 0;
  outline: none;
  border-radius: 8px;
  height: 97%;
}
