.not-found-container {
    text-align: center;
    margin: 80px 40px;
    .error-title {
        font-weight: 600;
        margin-bottom: 8px;
    }
    .error-info {
        line-height: 21px;
    }
}