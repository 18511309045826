.better-bank-statement-box {
  margin-top: 48px;
  .line-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .line {
    flex: 1;
    height: 0.5px;
    background-color: #a7a8b3;
  }

  .or-text {
    padding: 0 10px;
  }
  .bank-statement-instruction {
    margin-top: 36px;
    p {
      font-size: 14px;
      font-weight: 600;
      color: #161719;
    }
    :first-child {
      color: var(--active-blue-color);
    }
    :last-child {
      margin-top: 30px;
    }
  }
  .better-statement-input-box {
    margin-top: 25px;
  }
  .error-msg {
    color: #cc0000;
    font-size: 12px;
    padding: 0 16px;
    margin-top: 4px;
  }
  .non-error-helper-msg {
    color: var(--active-blue-color);
    font-size: 12px;
    padding: 0 16px;
    margin-top: 4px;
  }

  .upload-additional-doc {
    color: var(--active-blue-color);
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }

  .file-pass-instruction {
    margin-top: 29px;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      color: #161719;
      margin-bottom: 8px;
    }
  }
  .submit-btn {
    margin-top: 34px;
  }
}
