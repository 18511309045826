.loans-container {
    width: 100%;
    .tabs {
        margin-top: 24px;
        .tablinks  {
            margin: 0;
            outline: 0;
            border: 0;
            background-color: unset;
            width: 50%;
            padding-bottom: 8px;
            border-bottom: 2px solid #C0C1C8;
            color: #C0C1C8;
            font-family: unset;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
        }
        .active {
            border-color: #161719;
            color: #161719;
        }
    }
}