.container {
  /* height: 300; */
  /* background-color: red; */
}
/* App.css */

/* General styles */
/* .body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  } */

/* Content styles */
/* .content {
    padding: 20px;
    text-align: center;
  } */

/* Media queries for responsiveness */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens (mobile devices) */
  /* .header {
      padding: 10px;
      font-size: 18px;
    }
  
    .back-arrow {
      font-size: 14px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .content {
      padding: 15px;
    } */
}
.dashboard-container {
  width: 100%;
}
.dashboard {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
}

.dashboard-profile {
  background-color: #fcc4ac;
  width: 10.6875rem;
  height: 10.6875rem;
  border-radius: 8px;
  position: relative;
  text-decoration: unset;
  color: var(--primary-color);
  p {
    margin: 10px 0 0 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--primary-color);
    position: absolute;
  }
}
.polygon {
  position: absolute;
}
.dashboard-loan {
  background-color: #b7acfc;
  width: 10.6875rem;
  height: 10.6875rem;
  border-radius: 8px;
  text-decoration: unset;
  color: var(--primary-color);
  position: relative;
  p {
    margin: 10px 0 0 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: var(--primary-color);
    position: absolute;
  }
}

.my-profile-image {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  bottom: 0.4rem;
  right: 0.4rem;
}
