.home-container {
    width: 100%;
    background-color: #101010;
    height: 500px;

    .home-header {
        height: 70px;
    }

    .body {
        background-color: #fff;
        height: 86%;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 10px;

        .card-container {
            display: flex;
            height: 140px;
            justify-content: space-around;
            
            .card {
                width: 40%;
                border-radius: 6px;
                box-shadow: 0px 0px 10px 1px #EEF5FF;
            }
        }

        .loan-card {
            height: 80px;
            background-color: #E0F4FF;
            margin-top: 10px;
            border-radius: 6px;
            display: flex;
            padding: 10px;
            gap: 4px;
            justify-content: space-between;

            .content-container {
                display: flex;
                flex-direction: column;
                flex: 0.7;
                justify-content: space-around;

                .card-title {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                }

                .card-subtitle {
                    margin: 0;
                    font-size: 12px;
                    text-align: left;
                }

                .card-btn {
                    background-color: #101010;
                    padding-block: 6px;
                    padding-inline: 15px;
                    width: fit-content;
                    border-radius: 6px;

                    .btn-title {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 600;
                        text-align: left;
                        color: #fff;
                    }
                }
            }

            .img-container {
                display: flex;
                flex: 0.3;
            }
        }

    }
}

