.regret-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 24px;

  .regret-img-container {
    display: flex;
    justify-content: center;
    img {
      width: 70px;
      height: 70px;
    }
  }

  .regret-main-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .regret-title-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      :first-child {
        font-size: 24px;
        font-weight: 600;
        color: var(--error-color);
        text-align: center;
      }

      :nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: var(--primary1-color);
        text-align: center;
      }
    }

    .regret-subtitle {
      font-size: 16px;
      font-weight: 400;
      color: var(--primary1-color);
      text-align: center;
    }
  }

  .regret-caption {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-grey2-color);
    text-align: center;
  }
}
.upload-doc {
  display: block;
  margin-top: 104px;
  .regret-img-container {
    margin-bottom: 32px;
  }
}
