.loan-card {
  margin-top: 24px;
  border: 1px solid #d8d9de;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px rgba(22, 23, 25, 0.06),
    0px 4px 8px -2px rgba(22, 23, 25, 0.1);

  .loan-type {
    padding: 8px 1rem;
    background-color: var(--secondary-lightgrey-color);
    font-weight: 600;
  }

  .loan-stats {
    margin: 1rem 1rem 24px;

    .loan-id {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .umr-no {
      margin-bottom: 1rem;
      color: var(--primary-grey-color);
    }

    .loan-params {
      display: flex;
      justify-content: space-between;

      .loan-block {
        .loan-label {
          font-size: 12px;
          line-height: 1.6;
        }

        .loan-value {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5;
        }
      }
    }
    .repayment {
      color: var(--active-blue-color);
      margin-top: 1rem;
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  padding: 16px;
}

.modal-header {
  border-bottom: 1px solid var(--primary-lightgrey-color);
  display: flex;
  justify-content: space-between;
}

.modal-content {
  .modal-scroll {
    // max-height: 400px;
    overflow-y: auto;

    tbody {
      tr:nth-child(even) {
        background-color: var(--ghostwhite-color);
      }
    }
    thead {
      padding: 1rem 2rem;

      th {
        color: var(--primary-color);
        font-size: 1rem;
        font-weight: 600;
        width: 25%;
      }
    }
  }
  th,
  td {
    padding: 8px;
    border-bottom: 1px dashed #ccc;
  }
  th {
    border-bottom: 2px solid #333;
  }
}
