.kyc-document-container {
  .kyc-document-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    margin-inline: 16px;
    margin-top: 24px;

    @media screen and (max-width: 376px) {
      margin-inline: 0;
    }
    .kyc-doc-inputBox {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .instructions-container {
        .instructions-title {
          font-weight: 900;
        }
        .instructions-item {
          font-weight: 300;
          span {
            color: var(--active-blue-color);
            text-decoration: underline;
          }
        }
      }
      .error-msg {
        font-size: 12px;
        color: #fe370c;
      }
    }
    .udyam-details-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
    }
    .kyc-doc-error {
      font-size: 12px;
      margin-top: 4px;
      margin-inline: 16px;
      color: #c00;
    }
    .doc-instruction {
      align-items: center;
      margin-top: 8px;
      display: flex;
      gap: 8px;
      p {
        font-size: 12px;
        color: #767888;
      }
    }
  }
  .submit-btn {
    position: sticky;
    top: 100%;
    width: 100%;
    margin-top: 1rem;
  }
}
.udyamHelper {
  color: var(--active-blue-color);
  text-decoration: underline;
}
