
.layout {
    // height: 109dvh;
    height: 100vh;

    .layout-header {
        display: flex;
        height: 8%;
    }

    .layout-body {
        display: flex;
        height: 84%;
        overflow-y: scroll;
        padding: 10px 15px 0 15px;
    }

    .layout-footer {
        display: flex;
        height: 8%;
    }
}